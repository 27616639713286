import { useEffect, useState } from "react";
import comingsoon from "../../../Images/ComingSoon/coming-soon.jpg";
// import Shimmer from "../Common/ShimmerUI/Shimmer";
import Shimmer from "../../Common/ShimmerUI/Shimmer";

import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";
const AdvisoryBoard = () => {
    const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);
    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setImgLoading(true);
      };
      img.src = comingsoon;
    }, []);
  return (
    <>
      <MetaTags
        title={"Advisory Board: Meet Our Experts | Coming Soon"}
        Description={
          "Our Advisory Board brings expertise and vision to NDES. Stay tuned to learn more about the leaders guiding our mission. Coming soon!"
        }
        Keywords={
          "NDES advisory board Expert advisory panel Skill development experts Youth empowerment advisors Leadership guidance NDES"
        }
        href={"https://ndskills.in/advisoryBoard"}
      />

      {loading ? (
        <Shimmer />
      ) : (
        <div className="text-center ">
          {!imgLoading && (
            <Blurhash
              hash="U#OXIFj[^ioft7WCaya}~SfjE3aya{s:a}j?"
              height={500}
              width={500}
              punch={1}
              className="img-"
            />
          )}
          {imgLoading && (
            <img src={comingsoon} alt="Coming Soon" className="img-fluid" />
          )}
        </div>
      )}
    </>
  );
}

export default AdvisoryBoard
