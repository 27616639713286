// import { useEffect, useState } from "react";
// import Slider from "react-slick";
// import comingsoon from "../../../Images/ComingSoon/coming-soon.jpg";
// import Shimmer from "../../Common/ShimmerUI/Shimmer";
// import {
//   OverseasRepresentativesImages,
//   SeniorManagementTeamImages,
// } from "../Home/utils";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../../../CSS/main.css";

// const ManagementTeam = () => {
//   const [loading, setLoading] = useState(true);
//   const [imgLoading, setImgLoading] = useState(false);

//   const divStyle = {
//     color: "#183b56",
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000);
//   }, []);

//   useEffect(() => {
//     const img = new Image();
//     img.onload = () => {
//       setImgLoading(true);
//     };
//     img.src = comingsoon;
//   }, []);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//     ],
//   };

//   return (
//     <>
//       {loading ? (
//         <Shimmer />
//       ) : (
//         <div>
//           <div className="text-center bg-light ">
//             <h1 className="fs-3 pt-5 pb-3" style={divStyle}>
//               Senior Management Team
//             </h1>
//             <div className="mx-auto" style={{ maxWidth: "95%" }}>
//               <Slider {...settings}>
//                 {SeniorManagementTeamImages.map((Image, i) => (
//                   <div
//                     key={i}
//                     className="text-center p-4"
//                     style={{
//                       overflow: "hidden",
//                       width: "350px",
//                       height: "450px",
//                     }}
//                   >
//                     <div
//                       className="border bg-white rounded-4 border-white d-flex flex-column align-items-center"
//                       style={{
//                         width: "100%",
//                         height: "100%",
//                         padding: "15px",
//                       }}
//                     >
//                       <div
//                         className="w-100"
//                         style={{
//                           height: "300px",
//                           overflow: "hidden",
//                         }}
//                       >
//                         <img
//                           src={Image.url}
//                           alt={Image.alt}
//                           className="rounded-top-4 w-100 object-fit-contain"
//                           style={{
//                             height: "100%",
//                           }}
//                         />
//                       </div>
//                       <p>{Image.name}</p>
//                       <p>{Image.role}</p>
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </div>

//           <div className="text-center mt-4 bg-light ">
//             <h3 style={divStyle} className="pt-5 pb-3">
//               Overseas Representatives
//             </h3>
//             <div
//               className="row d-flex justify-content-around"
//               style={{ maxWidth: "100%" }}
//             >
//               {OverseasRepresentativesImages.map((Image, i) => (
//                 <div key={i} className="col-4  text-center p-5 ">
//                   <div
//                     className="border shadow-sm bg-white rounded-4 border-white"
//                     style={{ overflow: "hidden" }}
//                   >
//                     <img
//                       src={Image.url}
//                       alt={Image.alt}
//                       className="rounded-4 w-100 object-fit-contain"
//                     />
//                     <p>{Image.name}</p>
//                     <p>{Image.role}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ManagementTeam;


import { useEffect, useState } from "react";
import comingsoon from "../../../Images/ComingSoon/coming-soon.jpg";
// import Shimmer from "../Common/ShimmerUI/Shimmer";
import Shimmer from "../../Common/ShimmerUI/Shimmer";

import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";
const ManagementTeam = () => {
    const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);
    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setImgLoading(true);
      };
      img.src = comingsoon;
    }, []);
  return (
    <>
      <MetaTags
        title={"Management Team: Meet Our Visionary Leaders | Coming Soon"}
        Description={
          "Our Management Team is shaping the future. Stay tuned for insights into the leaders driving innovation and success at NDES. Coming soon!"
        }
        Keywords={
          "Management team NDES Visionary leaders India NDES leadership Innovation in skill development Future-focused leadership"
        }
        href={"https://ndskills.in/managementTeam"}
      />

      {loading ? (
        <Shimmer />
      ) : (
        <div className="text-center ">
          {!imgLoading && (
            <Blurhash
              hash="U#OXIFj[^ioft7WCaya}~SfjE3aya{s:a}j?"
              height={500}
              width={500}
              punch={1}
              className="img-"
            />
          )}
          {imgLoading && (
            <img src={comingsoon} alt="Coming Soon" className="img-fluid" />
          )}
        </div>
      )}
    </>
  );
}

export default ManagementTeam
